import type { InputRef } from 'antd';
import { message } from 'antd';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import _ from 'lodash';
import { action, computed, observable } from 'mobx';
import React from 'react';
import { playVoice, voiceData } from '../../../utils';
import type { ReturnGoodsReceiptData } from './types';

export class Store {
  @observable public returnGoodsReceiptData: ReturnGoodsReceiptData = {};

  @computed
  public get isSuccess(): boolean {
    return !_.isEmpty(this.returnGoodsReceiptData);
  }

  @observable public uniqueCode = '';

  public uniqueCodeRef: React.RefObject<InputRef> = React.createRef();

  @action public handleUniqueCode = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.uniqueCode = event.target.value;
  };

  @action public handleUniqueCodePressEnter = (uniqueCode: string) => {
    this.uniqueCode = '';
    this.uniqueCodeRef.current?.focus();
    this.returnGoodsReceiptData = {};

    if (!uniqueCode) {
      playVoice(voiceData.请输入唯一码);
      return;
    }

    request<BaseData<ReturnGoodsReceiptData>>({
      url: '/api/cloud/pms/return/order/arrival',
      method: 'POST',
      data: {
        uniqueCode,
        pic: null,
      },
    })
      .then((info) => {
        playVoice(voiceData.通过);
        message.success({
          key: '通过',
          content: '通过',
        });
        this.returnGoodsReceiptData = info.data || {};
      })
      .catch(() => playVoice(voiceData.错误));
  };
}
