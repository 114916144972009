import { Input, Typography } from 'antd';
import { egeniePcTheme } from 'egenie-common';
import { observer } from 'mobx-react';
import React from 'react';
import { GoodsWrapper, ScanWrapper } from '../../../utils';
import { Store } from './store';

@observer
class Left extends React.Component<{ store: Store; }> {
  render() {
    const {
      uniqueCode,
      uniqueCodeRef,
      handleUniqueCode,
      handleUniqueCodePressEnter,
    } = this.props.store;

    return (
      <section>
        <Typography.Title
          level={2}
          style={{ paddingBottom: egeniePcTheme.spacing['egenie-spacing-sm'] }}
        >
          请扫描唯一码
        </Typography.Title>
        <Input
          allowClear
          autoComplete="off"
          autoFocus
          onChange={handleUniqueCode}
          onPressEnter={(event: any) => handleUniqueCodePressEnter(event.target.value)}
          placeholder="请扫描唯一码"
          ref={uniqueCodeRef}
          size="large"
          value={uniqueCode}
        />
      </section>
    );
  }
}

@observer
class Right extends React.Component<{ store: Store; }> {
  render() {
    const { returnGoodsReceiptData } = this.props.store;
    return (
      <>
        <Typography.Title
          level={2}
          style={{ paddingBottom: egeniePcTheme.spacing['egenie-spacing-sm'] }}
        >
          扫描图片
        </Typography.Title>
        <GoodsWrapper
          data={returnGoodsReceiptData}
          style={{
            marginTop: egeniePcTheme.spacing['egenie-spacing-sm'],
            width: 600,
          }}
        />
      </>
    );
  }
}

export default function() {
  const store = React.useMemo(() => new Store(), []);

  return (
    <ScanWrapper
      leftChildren={<Left store={store}/>}
      leftTitle="退货收货"
      leftWidth={416}
      rightChildren={<Right store={store}/>}
      rightTitle="扫描结果"
    />
  );
}

